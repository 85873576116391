import { basePost } from '@/js/services/baseService';
import { useStore } from 'vuex';
import { reactive } from 'vue';
export default function (obj, orderLocation) {
    const { state } = useStore();

    // 搜尋是否有該會員電話
    const handleMemberPhoneByBlur = async (val) => {
        try {
            const res = await basePost('/api_customer/customer_data', {
                customer_phone: val.value,
                store_id: state.auth.store_id,
            });

            const { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                obj.name = data.res_data.name;
                customerData.data = data.res_data;
                orderLocation.data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].reduce((prev, curr) => {
                    return {
                        ...prev,
                        [curr]: data.res_data.order_location[curr] || []
                    }
                },{});
                obj.customer_source = data.res_data.source;
                obj.customer_remark = data.res_data.remark;
            }
        } catch (err) {
            console.error(err);
        }
    };

    const customerData = reactive({ data: {} });

    return { handleMemberPhoneByBlur, customerData };
}
