<template>
    <div class="tab-pane fade show" role="tabpanel" :id="id" :class="{ active: idx === 0 }">
        <div class="side-menu-wrap">
            <form class="side-menu" @submit="handleStartService">
                <div class="side-menu-close" @click="closeStartServiceLightbox()">
                    <img src="@/assets/tgd/lightbox/close.svg" alt="" />
                </div>
                <div class="side-menu-top">
                    <div class="side-menu-item">
                        <div class="title mb-3">服務資訊：</div>
                        <div class="row form m-0">
                            <div class="col-5">
                                <BaseInput
                                    title="會員電話"
                                    placeholder="請輸入"
                                    type="tel"
                                    class="form-control"
                                    name="customer_phone"
                                    @inputBlurred="handleMemberPhoneByBlur"
                                />
                            </div>
                            <div class="col-7">
                                <BaseInput
                                    title="會員名稱"
                                    placeholder="請輸入"
                                    type="text"
                                    class="form-control"
                                    name="name"
                                />
                            </div>
                            <div class="col-5">
                                <BaseInput title="開始時間" class="form-control" :replaceBySlot="true">
                                    <Datepicker
                                        :timePicker="true"
                                        v-model="startTime"
                                        :startTime="initTime"
                                        hideInputIcon
                                    />
                                </BaseInput>
                            </div>
                            <div class="col-7">
                                <BaseInput
                                    title="結束時間"
                                    placeholder="請輸入"
                                    type="text"
                                    class="form-control end-text"
                                    name="end_time"
                                    :readonly="true"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="side-menu-item">
                        <div class="title-group mb-3">
                            <div class="title">服務內容：</div>
                        </div>
                        <div class="row form item-top m-0">
                            <div class="col-5">
                                <BaseSelect
                                    title="芳療師"
                                    name="member_id"
                                    :options="aroma.list"
                                    :disabled="!serviceIdList.length"
                                    isShowInitVal
                                    isFilterSearch
                                />
                            </div>
                            <div class="col-7" style="z-index: 100">
                                <div class="row m-0">
                                    <div class="col-6 pe-1">
                                        <BaseSelect
                                            title="房間"
                                            name="room_id"
                                            :options="convertArr(orderData.roomAry)"
                                        />
                                    </div>
                                    <div class="col-6 ps-1">
                                        <BaseSelect title="床號" name="room_bed_id" :options="convertArr(bedNumArr)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form service-content m-0">
                            <div class="col-6 ps-0 pe-4">
                                <div class="input-group phone-btn mb-3">
                                    <button
                                        type="button"
                                        class="btn"
                                        @click="
                                            push({
                                                service_id: orderData.serviceAry[0].id,
                                                type: 2,
                                                txt: orderData.serviceAry[0].price,
                                                is_old: 0
                                            })
                                        "
                                    >
                                        <img src="@/assets/tgd/lightbox/add-button.svg" alt="" />
                                        <span> 新增服務 </span>
                                        <p></p>
                                    </button>
                                </div>
                            </div>
                            <div class="col-6"></div>
                            <template v-for="(service, index) in fields" :key="service.key">
                                <div class="col-5">
                                    <BaseSelect
                                        :name="`service_content[${index}].service_id`"
                                        :options="convertArr(orderData.serviceAry)"
                                        @selectChange="changeService(index)"
                                        isFilterSearch
                                    />
                                </div>
                                <div class="col-7 wrap" :style="{ 'z-index': 99 - index }">
                                    <div class="cash-wrap">
                                        <BaseSelectInput
                                            selectClass="select-coupon"
                                            inputClass="coupon-text"
                                            :selectName="`service_content[${index}].type`"
                                            :inputName="`service_content[${index}].txt`"
                                            :options="paymentType"
                                            @selectChange="changeService(index)"
                                            @txtInputBlur="getTicketList"
                                        />
                                    </div>
                                    <label
                                        class="d-inline-flex align-self-start align-items-center"
                                        style="height: 53px"
                                    >
                                        <input
                                            type="checkbox"
                                            :false-value="0"
                                            :true-value="1"
                                            v-model="values.service_content[index].is_old"
                                        />
                                        <span>老點</span>
                                    </label>
                                    <div class="cancel-img" @click.prevent.stop="remove(index)">
                                        <a href="javascript:;">
                                            <img src="@/assets/tgd/lightbox/del.svg" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="row form service-content m-0" v-if="externalList.data.length">
                            <div class="col-6 ps-0 pe-4" style="position: relative; z-index: 1">
                                <div class="input-group phone-btn mb-3">
                                    <button
                                        type="button"
                                        class="btn violation"
                                        @click="
                                            pushExternal({
                                                external_id: externalList.data[0].id,
                                                type: 3,
                                                external_number: '',
                                                list: externalList.data[0].services.map((item) => {
                                                    return { ...item, is_old: 0, service_id: item.id };
                                                }),
                                            })
                                        "
                                    >
                                        <img src="@/assets/tgd/lightbox/add-button.svg" alt="" />
                                        <span> 新增外部券服務 </span>
                                        <p></p>
                                    </button>
                                </div>
                            </div>
                            <div class="col-6"></div>
                            <template v-for="(external, idx) in fieldsExternal" :key="external.key">
                                <div class="col-5 position-relative violation">
                                    <BaseSelect
                                        title=""
                                        :options="externalList.data"
                                        :name="`external_content[${idx}].external_id`"
                                        @selectChange="changeExternal(idx)"
                                        isFilterSearch
                                    />
                                </div>
                                <div class="col-7">
                                    <BaseInput
                                        title="券號"
                                        placeholder="請輸入"
                                        type="text"
                                        class="form-control"
                                        :name="`external_content[${idx}].external_number`"
                                    />
                                    <div class="cancel-img" @click="removeExternal(idx)">
                                        <a href="javascript:;">
                                            <img src="@/assets/tgd/lightbox/del.svg" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="col-12 d-flex align-items-start px-0"
                                    v-for="(item, i) in values.external_content[idx].list"
                                    :key="i"
                                >
                                    <div class="external-info-wrap">
                                        <BaseInput
                                            type="text"
                                            class="form-control border-radius-all"
                                            :value="item.name"
                                            :name="`external_content[${idx}].list[${i}].name`"
                                            :disabled="true"
                                        />
                                    </div>
                                    <label
                                        class="d-inline-flex align-self-start align-items-center"
                                        style="height: 53px"
                                    >
                                        <input
                                            type="checkbox"
                                            :false-value="0"
                                            :true-value="1"
                                            v-model="values.external_content[idx].list[i].is_old"
                                        />
                                        <span>老點</span>
                                    </label>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="side-menu-bottom">
                    <div class="side-menu2-item">
                        <div class="row sum m-0">
                            <div class="col-5">小計</div>
                            <div class="col-7">${{ numberFormat(amount) }}</div>
                        </div>
                    </div>
                    <div class="side-menu2-item">
                        <div class="form">
                            <div class="row m-0">
                                <div class="col-5">
                                    <BaseInput
                                        title="券號"
                                        placeholder="輸入折價券號"
                                        type="text"
                                        class="form-control"
                                        name="discount_number_list[0]"
                                        @blur="verifyDiscountNum()"
                                    />
                                </div>
                                <div class="col-7 d-flex justify-content-between pe-3 mb-2">
                                    <div v-if="discountPrice.msg" class="red">{{ discountPrice.msg }}</div>
                                    <div v-else>{{ discountPrice.name }}</div>
                                    <div class="last-color">
                                        {{ discountPrice.price ? `-${numberFormat(discountPrice.price)}` : 0 }}
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-5">特約</div>
                                <div class="col-7">
                                    <BaseCalcSelectInput
                                        selectDate="1"
                                        selectStyle="width: 56%"
                                        inputClass="last-color"
                                        placeholder="請輸入"
                                        selectName="special_id"
                                        inputName="special_price"
                                        :options="convertArr(orderData.specialAry)"
                                        :readonly="true"
                                        isFilterSearch
                                    />
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-5">增補</div>
                                <div class="col-7">
                                    <BaseCalcSelectInput
                                        selectDate="1"
                                        selectStyle="width: 56%"
                                        selectClass="select-icon"
                                        placeholder="請輸入"
                                        selectName="calc_select_name"
                                        inputName="supplement"
                                        :options="[
                                            { id: 0, name: '+' },
                                            { id: 1, name: '-' },
                                        ]"
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-5">現金付款</div>
                                <div class="col-7">
                                    <BaseCalcSelectInput
                                        selectDate=""
                                        inputClass="custom-radius"
                                        placeholder="請輸入"
                                        selectName="select_payment"
                                        inputName="cash_payment"
                                    />
                                </div>
                            </div>
                            <div class="row no-gutters">
                                <div class="col-5">剩餘款項</div>
                                <div class="col-7">
                                    <div class="row no-gutters">
                                        <div class="col-8 px-0">
                                            <BaseSelect
                                                title=""
                                                placeholder="付款方式選擇"
                                                rootClass="no-style mb-0"
                                                iconColor="0f2a68"
                                                isValueString
                                                :options="paymentMethod"
                                                name="payment_method"
                                            />
                                        </div>
                                        <div class="col-4 px-0 text-end fz-20 last-color">${{ numberFormat(remainMoney) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="side-menu2-item">
                        <div class="form">
                            <div class="row m-0">
                                <div class="col-5">統編</div>
                                <div class="col-7">
                                    <BaseCalcSelectInput
                                        selectDate=""
                                        inputClass="custom-radius"
                                        placeholder="請輸入"
                                        selectName="select_uniform"
                                        inputName="uniform_numbers"
                                    />
                                </div>
                            </div>
                            <div class="row pt-3 m-0">
                                <div class="col-5 col-md-5">
                                    <div class="input-group">
                                        <button
                                            type="button"
                                            class="btn btn-block btn-cancel"
                                            @click="handleDelReservation"
                                        >
                                            刪除預約
                                        </button>
                                    </div>
                                </div>
                                <div class="col-7 col-md-7">
                                    <div class="input-group">
                                        <button
                                            type="submit"
                                            class="btn btn-block btn-checkout"
                                            :disabled="isSubmitting"
                                        >
                                            <span>開始服務</span>
                                            <div class="price">
                                                <span>NT$</span>
                                                <strong>
                                                    {{ numberFormat(totalAmount) }}
                                                </strong>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, watch, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useForm, ErrorMessage, useFieldArray } from 'vee-validate';
import * as yup from 'yup';
import { basePost } from '@/js/services/baseService';
import useHandleLightboxOpen from '@/hooks/common/useHandleLightboxOpen';
import useCheckMemberPhone from '@/hooks/reservation/useCheckMemberPhone';
import useSwalCustom from '@/hooks/common/useSwalCustom';
import useDiscountPrice from '@/hooks/common/useDiscountPrice';
import useAromaList from "@/hooks/common/useAromaList";
import { numberFormat } from "@/js/functions";

export default {
    name: 'Order',
    components: { ErrorMessage },
    props: {
        orderData: {
            type: Object,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        idx: Number,
    },
    setup({ orderData, idx }) {
        const { closeStartServiceLightbox } = useHandleLightboxOpen();
        const { roomAry, answer_list, specialAry, serviceAry } = orderData;
        const { state, commit } = useStore();
        const storeId = computed(() => state.auth.store_id);
        const orderList = computed(() => state.startServiceLightbox.list);
        const orderId = computed(() => state.startServiceLightbox.id);
        const { getDiscountPrice } = useDiscountPrice();
        // 開始時間
        const startTimeArr = answer_list.demand[idx].start_time.split(':');
        const initTime = reactive({
            hours: startTimeArr[0],
            minutes: startTimeArr[1],
        });
        const startTime = ref({
            hours: startTimeArr[0],
            minutes: startTimeArr[1],
        });

        const serviceObj = serviceAry.reduce((prev, curr) => {
            return { ...prev, [curr.id]: curr };
        }, {});

        // form驗證設定
        const validationSchema = yup.object().shape({
            customer_phone: yup
                .string()
                .trim('不可留空')
                .matches(/^09[0-9]{8}$/, '手機格式不正確')
                .required('必填'),
            name: yup.string().required('必填'),
            end_time: yup.string(),
            member_id: yup.string().required('必填'),
            room_id: yup.number().min(1, '必填'),
            room_bed_id: yup.number().min(1, '必填'),
            service_content: yup
                .array()
                .of(
                    yup.object().shape({
                        service_id: yup.mixed().requireStringOrNumber('必填'),
                        type: yup.mixed(),
                        txt: yup.mixed(),
                        is_old: yup.mixed(),
                    })
                )
                .strict(),
            special_id: yup.string(),
            special_price: yup.string(),
            calc_select_name: yup.string(),
            supplement: yup.string(),
            cash_payment: yup.string(),
            // payment_method: yup.string().when("cash_payment", {
            //     is: (val) => {
            //         if (!val) {
            //             return true;
            //         }

            //         return remainMoney.value > 0;
            //     },
            //     then: (schema) => schema.required("必填"),
            // }),
            uniform_numbers: yup.string().matches(/^[0-9]{8}$/, {
                message: '格式不對',
                excludeEmptyString: true,
            }),
        });
        const { values, handleSubmit, isSubmitting, setValues, errors } = useForm({
            validateOnMount: false,
            initialTouched: {
                customer_phone: true,
            },
            validationSchema,
        });
        const { push, fields, remove } = useFieldArray('service_content');
        const {
            remove: removeExternal,
            push: pushExternal,
            fields: fieldsExternal,
        } = useFieldArray('external_content');
        const { handleMemberPhoneByBlur } = useCheckMemberPhone(values);
        // 付款方式
        const paymentType = [
            { id: 1, name: '用券' },
            { id: 2, name: '現金' },
        ];
        // 床號
        let bedNumArr = computed(() => {
            let idx = 0;
            for (let i = 0; i < roomAry.length; i++) {
                if (values.room_id === roomAry[i].id) {
                    idx = i;
                    break;
                }
            }

            return roomAry[idx].roomBedAry;
        });

        const amount = ref(0);
        // // 小計
        // let amount = computed(() => {
        //     let num = 0;

        //     if (values.service_content) {
        //         values.service_content.forEach((content) => {
        //             if (content.type == 2) {
        //                 let addedNum = parseInt(content.txt);
        //                 if (!Number.isNaN(addedNum)) {
        //                     num += addedNum;
        //                 }
        //             }
        //         });
        //         num += spreadAmount.value;
        //         return num;
        //     }
        //     return num;
        // });
        // 剩餘款項
        let remainMoney = computed(() => {
            let cashPayment = 0;

            if (values.cash_payment) {
                cashPayment = values.cash_payment.replace('$', '');
                cashPayment = cashPayment.replace(',', '');
                cashPayment = Number(cashPayment);
            }

            let mathSymbol = values.calc_select_name ? '-' : '+';
            let supplement = mathSymbol + (values.supplement ? values.supplement : 0);

            return (
                Number(amount.value) -
                Number(values.special_price) +
                Number(supplement) -
                cashPayment -
                discountPrice.price
            );
        });
        // 總額
        let totalAmount = computed(() => {
            let mathSymbol = values.calc_select_name ? '-' : '+';
            let supplement = mathSymbol + (values.supplement ? values.supplement : 0);

            return Number(amount.value) - Number(values.special_price) + Number(supplement) - discountPrice.price;
        });
        let swalCustom = new useSwalCustom({
            title: '開始服務!',
            icon: 'success',
            confirmButtonText: '確定',
        });

        // 塞入初始值
        const initValues = () => {
            const serviceContent = answer_list.demand[idx].serviceAry.map((service) => {
                return {
                    service_id: service.service_id,
                    type: service.saleType,
                    txt: service.txt,
                    is_old: service.is_old,
                };
            });

            const externalContent = answer_list.demand[idx].externalAry.map((external) => {
                const { external_id, external_number, list } = external;
                list.forEach((val) => {
                    val.name = serviceObj[val.service_id].name;
                });
                return {
                    external_id,
                    external_number,
                    list,
                };
            });

            const payload = {
                customer_phone: answer_list.customer_phone,
                name: answer_list.customer_name,
                end_time: answer_list.demand[idx].end_time,
                member_id: String(answer_list.demand[idx].member_id),
                room_id: String(answer_list.demand[idx].room_id),
                room_bed_id: String(answer_list.demand[idx].room_bed_id),
            };

            if (serviceContent.length) {
                payload.service_content = serviceContent;
            }

            if (externalContent.length) {
                payload.external_content = externalContent;
            }

            amount.value = answer_list.demand[idx].amount;

            setValues(payload);
        };
        initValues();

        // 刪除預約
        const delReservation = () => {
            const hasOrder = orderList.value.answer_list.demand.length;

            if (hasOrder === 1) {
                closeStartServiceLightbox();
                commit('startServiceLightbox/delListByIdx', 0);
                commit('reservation/delListById', orderId.value);
                return;
            }

            commit('startServiceLightbox/delListByIdx', idx);
        };

        // 取得預約服務id
        const getReserveDetailId = () => {
            let reserve_details_id = 0;

            if (answer_list.demand.length === 1) {
                reserve_details_id = answer_list.demand[0].reserve_details_id;
            } else {
                reserve_details_id = answer_list.demand[idx].reserve_details_id;
            }

            return reserve_details_id;
        };

        // 送出開始服務
        const handleStartService = handleSubmit(async (values, actions) => {
            try {
                const {
                    customer_phone,
                    room_id,
                    room_bed_id,
                    member_id,
                    service_content,
                    special_id,
                    special_price,
                    supplement,
                    cash_payment,
                    payment_method,
                    uniform_numbers,
                    external_content,
                    discount_number_list,
                } = values;

                if (!service_content && !external_content) {
                    swalCustom.fire({
                        title: '請選擇服務',
                        icon: 'error',
                    });
                    return;
                }

                let reserve_details_id = getReserveDetailId();

                const serviceContent = mixService(service_content, external_content);

                let discountNumber = [];
                if (discount_number_list && discount_number_list[0] !== '') {
                    discountNumber = discount_number_list;
                }
                const resOrderCheck = await basePost('/api_reserve/reserve_check_order', {
                    reserve_id: answer_list.reserve_id,
                    reserve_details_id,
                    store_id: storeId.value,
                    customer_phone,
                    start_time: `${startTime.value.hours}:${startTime.value.minutes}`,
                    room_id,
                    room_bed_id,
                    member_id,
                    service_content: JSON.stringify(serviceContent),
                    amount: amount.value,
                    special_id,
                    special_price: special_price ? special_price : 0,
                    supplement: values.calc_select_name == 0 ? supplement : -supplement,
                    cash_payment: cash_payment.replace('$', ''),
                    payment_method: payment_method ? payment_method : '',
                    uniform_numbers,
                    discount_number: JSON.stringify(discountNumber),
                });

                const { status, data } = resOrderCheck;

                if (status === 200 && data.res_code === 1) {
                    swalCustom.fire().then(() => {
                        delReservation();
                    });
                } else {
                    swalCustom.fire({
                        title: data.res_content,
                        icon: 'error',
                    });
                }
            } catch (err) {
                swalCustom.fire({
                    title: err.message,
                    icon: 'error',
                });

                console.error(err);
            }
        });

        // 外部券清單
        let externalList = reactive({ data: [] });
        // 取得外部券清單
        const getExternalList = async () => {
            const res = await basePost('/api_external/external_list', {
                store_id: state.auth.store_id,
            });
            const { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                const { res_data } = data;
                externalList.data = res_data;
            }
        };
        getExternalList();

        const convertArr = (arr) => {
            return arr.map((item) => {
                item.id = String(item.id);

                return item;
            });
        };

        const changeExternal = (idx) => {
            const thisExternal = values.external_content[idx];
            const list = externalList.data.find((item) => {
                return item.id === thisExternal.external_id;
            });
            values.external_content[idx].list = list.services.map((item) => {
                return { ...item, is_old: 0, service_id: item.id };
            });
        };

        // 操作刪除預約
        const handleDelReservation = async () => {
            try {
                let reserve_details_id = getReserveDetailId();

                const res = await basePost('/api_reserve/reserve_details_cancel', {
                    store_id: storeId.value,
                    reserve_details_id,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    swalCustom
                        .fire({
                            title: '刪除服務成功',
                        })
                        .then(() => {
                            delReservation();
                        });
                }
            } catch (err) {
                swalCustom.fire({
                    title: err.message,
                    icon: 'error',
                });
                console.error(err);
            }
        };

        // 更換選項時
        const changeService = (idx) => {
            if (values.service_content[idx].type == 2) {
                const serviceId = values.service_content[idx].service_id;
                values.service_content[idx].txt = orderData.serviceAry.find((item) => item.id == serviceId).price;
            }
        };

        // 特約
        watch(
            () => values.special_id,
            (id) => {
                if (id === undefined) {
                    values.special_price = 0;
                    return;
                }

                if (!id) return;

                values.special_price = specialAry.find((special) => special.id === id).price;
            }
        );

        // 現金付款
        watch(
            () => values.cash_payment,
            (val) => {
                if (!val.indexOf('$') || !val) return;
                values.cash_payment = '$' + val.replace('$', '');
            }
        );

        // 結束時間
        watch(
            [() => values.service_content, () => values.external_content, () => startTime],
            (arr) => {
                const serviceContent = arr[0];
                const externalContent = arr[1];
                let totalTime = 0;

                if (serviceContent) {
                    serviceContent.forEach((service) => {
                        for (let i = 0; i < serviceAry.length; i++) {
                            if (service.service_id === serviceAry[i].id) {
                                totalTime += serviceAry[i].time;
                            }
                        }
                    });
                }

                if (externalContent) {
                    externalContent.forEach((item) => {
                        if (!item.list) return;
                        item.list.forEach((val) => {
                            totalTime += val.time;
                        });
                    });
                }

                let plusHours = Number(Math.floor(totalTime / 60));
                let plusMinutes = Number(totalTime % 60);
                let hours = Number(startTime.value.hours) + plusHours;
                let minutes = '';

                if (Number(startTime.value.minutes) + plusMinutes === 0) {
                    minutes = '00';
                } else {
                    let totalMinutes = Number(startTime.value.minutes) + plusMinutes;

                    if (totalMinutes >= 60) {
                        hours += Math.floor(totalMinutes / 60);
                        minutes = totalMinutes % 60;
                    } else {
                        minutes = totalMinutes;
                    }
                }

                values.end_time = `${hours}:${minutes}`;
            },
            { deep: true }
        );

        const paymentMethod = computed(() => {
            return orderData.payment_method.map((item) => ({
                id: item.key,
                name: item.name,
            }));
        });

        let discountPrice = reactive({
            name: '',
            price: 0,
            msg: '',
        });
        const verifyDiscountNum = async () => {
            if (values.discount_number_list[0] === '') {
                discountPrice.msg = '';
                discountPrice.name = '';
                discountPrice.price = 0;
                return;
            }
            try {
                const data = await getDiscountPrice(values.discount_number_list[0]);
                discountPrice.price = data.price;
                discountPrice.name = data.discount_name;
                discountPrice.msg = '';
            } catch (data) {
                discountPrice.price = 0;
                discountPrice.name = '';
                discountPrice.msg = data.res_content;
            }
        };

        const { mixService, getGoodAtService } = useAromaList(storeId.value);

        const serviceIdList = computed(()=>{
            if(!values) return [];
            return mixService(values.service_content, values.external_content)
            .map(item => Number(item.service_id))
            .filter((item, index, arr) => {
                return arr.indexOf(item) === index;
            })
        });

        const aromaList = computed(()=>{
            return convertArr(orderData.answer_list.demand[idx].member_list)
        });

        const getAromaList = async (arr)  => {
            try {
                const res = await getGoodAtService(arr);
                aroma.data = res;
            } catch (err) {
                console.error(err);
            }
        }
        
        watch(serviceIdList, (serviceIdList) => {
            if(!serviceIdList.length) return;
            getAromaList(serviceIdList);
        })

        const aroma = reactive({
            data: null,
            list: computed(() => {
                return aroma.data ?
                    aroma.data.filter(item => item.is_punch == 1) :
                    aromaList.value
            })
        });

        // 取得票券差額
        const getTicketList = async () => {
            const serviceContent = ticketList.value;
            const res = await basePost('/api_order/calculate_ticket_sales_service_spread', {
                store_id: storeId.value,
                service_content: JSON.stringify(serviceContent),
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                amount.value = data.res_data.amount;
            }else {
                swalCustom.fire({
                    title: data.res_content,
                    icon: 'error',
                });
            }
        };


        const ticketList = computed(()=>{
            if(!values) return [];
            return [...(values.service_content || [])]
        });

        //  只給watch判斷是否需要重取小計
        const watchService = computed(()=>{
            if(!values) return [];
            return [...(values.service_content || [])].map(item => {
                return {
                    service_id: item.service_id,
                    type: item.type
                }
            })
        });

        watch(()=> watchService, ()=> {
            getTicketList();
        }, { deep: true, immediate: true })

        return {
            values,
            handleStartService,
            isSubmitting,
            remove,
            closeStartServiceLightbox,
            bedNumArr,
            amount,
            remainMoney,
            totalAmount,
            paymentType,
            startTime,
            initTime,
            handleMemberPhoneByBlur,
            handleDelReservation,
            errors,
            convertArr,
            changeService,
            push,
            fields,
            externalList,
            pushExternal,
            removeExternal,
            changeExternal,
            fieldsExternal,
            paymentMethod,
            discountPrice,
            verifyDiscountNum,
            serviceIdList,
            aroma,
            getTicketList,
            numberFormat
        };
    },
};
</script>

<style scoped lang="scss">
@import '~@/css/mixins';
@import '~@/css/grid';

.side-menu-wrap {
    overflow: overlay;
    height: 100vh;
    position: relative;
    width: 640px;
    &::-webkit-scrollbar-track {
        background-color: #d6d6d6;
        border-radius: 2px;
    }
    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background: #707070;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 9px;
    }
    @include phone {
        width: 100%;
        padding-top: 80px;
    }
    .side-menu {
        box-shadow: -3px -4px 41px #2e2e2e5e;
        position: absolute;
        z-index: 3;
        @include phone {
            width: 100%;
        }

        .item-top {
            .col-5 {
                flex: 0 0 40%;
                max-width: 40%;
            }
            .col-7 {
                flex: 0 0 60%;
                max-width: 60%;
            }
        }
        .service-content {
            .col-5 {
                flex: 0 0 35%;
                max-width: 35%;
            }

            .col-7 {
                flex: 0 0 65%;
                max-width: 65%;
            }
        }
        .col-5 {
            padding-left: 0;
            flex: 0 0 48%;
            max-width: 48%;
            @include phone {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
            }
        }
        .col-7 {
            padding-right: 0;
            padding-left: 0;
            flex: 0 0 52%;
            max-width: 52%;
            @include phone {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
            }
            .col-6 {
                padding-right: 0;
                padding-left: 0;
                select {
                    width: 50%;
                    padding-right: 31px !important;
                }
            }
        }
    }
    .side-menu-top {
        background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0% no-repeat
            padding-box;
        .side-menu-item {
            padding-top: 45px;
            padding-bottom: 20px;
            padding-left: 45px;
            padding-right: 72px;
            border-bottom: 1px solid #eee;
            @include phone {
                padding: 20px;
                padding-top: 35px;
            }

            label {
                input[type='checkbox'] {
                    display: none;
                }
                input[type='checkbox'] + span {
                    display: inline-block;
                    padding-left: 38px;
                    line-height: 28px;
                    background: url('~@/assets/tgd/lightbox/radio-none.svg') no-repeat;
                    user-select: none;
                }
                input[type='checkbox']:checked + span {
                    background: url('~@/assets/tgd/lightbox/radio-checked.svg') no-repeat;
                }
            }
            .col-7 {
                position: relative;
                .cancel-img {
                    position: absolute;
                    right: -40px;
                    top: 50%;
                    transform: translate(0%, -68%);
                    img {
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(130%);
                        }
                    }
                }
            }
            .wrap {
                @include phone {
                    flex: 0 0 88% !important;
                    max-width: 88% !important;
                    .cancel-img {
                        transform: translate(0%, -60%);
                    }
                }
            }
            .title-group {
                display: flex;
                align-items: center;
                label {
                    padding-left: 15px;
                    span {
                        font: normal normal bold 16px/21px Microsoft JhengHei;
                        letter-spacing: 0px;
                        color: #000000;
                        padding-left: 10px;
                    }
                }
            }
            .title {
                color: #a4641a;
                font-size: 18px;
                font-weight: bold;
            }
            .form {
                .input-group {
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    height: 53px;
                    background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                        no-repeat padding-box;

                    .input-group-text {
                        background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                            no-repeat padding-box;
                        border: none;
                        border-radius: 11px 0px 0px 11px;
                        padding: 15px;
                        position: relative;
                        font-weight: bold;
                        @include phone {
                            padding: 12px;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            border-right: 1px solid #eee;
                            top: 10px;
                            bottom: 10px;
                            right: 0;
                            z-index: 1;
                        }
                        .select-coupon {
                            border-radius: 11px 0px 0px 11px;
                            padding-right: 32px;
                            height: 100%;
                            color: #0f2a68;
                            background: #f9f9f9 url('~@/assets/tgd/lightbox/box1-arrow1.svg') no-repeat 90%;
                        }

                        .select-money {
                            border-radius: 11px 0px 0px 11px;
                            padding-right: 32px;
                            height: 100%;
                            color: #a4641a;
                            background: #f9f9f9 url('~@/assets/tgd/lightbox/box1-arrow2.svg') no-repeat 90%;
                        }
                    }
                    .end-text {
                        color: rgba(0, 0, 0, 0.5);
                    }
                    .form-control {
                        background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                            no-repeat padding-box;
                        border: none;
                        border-radius: 0px 11px 11px 0px;
                        padding: 14px;
                        font: normal normal 600 16px/22px Open Sans;
                    }
                    select {
                        flex: 1;
                        background: #f9f9f9 url('~@/assets/tgd/lightbox/select-arrow-box.svg') no-repeat 101%;
                        border: none;
                        border-radius: 11px;
                        padding: 15px;
                        font: normal normal 600 16px/22px Open Sans;
                        appearance: none;
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(130%);
                        }
                    }
                    .custom-rounded {
                        border-radius: 0px 11px 11px 0px;
                    }
                    .btn {
                        width: 100%;
                        border-radius: 11px;
                        padding: 15px;
                        background: transparent
                            linear-gradient(282deg, #a4641a 0% #b57831 36%, #a4641a 88%, #ebc496 100%);
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid #ffffff85;
                        &.violation {
                            background: transparent
                                linear-gradient(282deg, #481e00 0%, #9c5828 36%, #cf9064 88%, #8f5126 100%);
                        }
                    }
                }
                .phone-btn {
                    @include phone {
                        width: 136px !important;
                        height: 44px !important;
                    }
                    button {
                        @include phone {
                            width: 136px !important;
                            height: 44px !important;
                        }
                    }
                }
            }
        }
    }
    .side-menu-bottom {
        background: #e1e1e1;
        .col-5 {
            @include phone {
                flex: 1 1 40%;
            }
        }
        .col-7 {
            @include phone {
                flex: 1 1 60%;
            }
        }
        .col-4,
        .col-8 {
            @include phone {
                flex: 1 1 100%;
            }
        }
        .side-menu2-item {
            padding: 25px 0;
            padding-left: 45px;
            padding-right: 72px;
            border-bottom: 1px solid #ccc;
            @include phone {
                padding: 20px;
                padding-top: 35px;
            }
            label {
                margin-bottom: 10px;
                margin-right: 10px;
                input[type='radio'] {
                    display: none;
                }
                input[type='radio'] + span {
                    display: inline-block;
                    padding-left: 38px;
                    line-height: 28px;
                    background: url('~@/assets/tgd/lightbox/radio-none.svg') no-repeat;
                    user-select: none;
                }
                input[type='radio']:checked + span {
                    background: url('~@/assets/tgd/lightbox/radio-checked.svg') no-repeat;
                    color: #a4641a;
                }
                span {
                    font-size: 16px;
                }
            }
            .sum {
                letter-spacing: 0px;
                color: #4a4a4a;
                .col-5 {
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                }
                .col-7 {
                    font: normal normal bold 20px/27px Open Sans;
                    text-align: right;
                }
            }
            .col-md-5 {
                @include phone {
                    padding-right: 5px;
                }
            }
            .col-md-7 {
                @include phone {
                    padding-left: 5px;
                }
            }

            .form {
                font: normal normal bold 18px/24px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #000000;
                .row {
                    align-items: center;
                    padding-bottom: 10px;
                    .last-color {
                        color: #d94343;
                        font: normal normal 600 20px/22px Open Sans;
                    }
                    .fz-20 {
                        font-size: 20px;
                    }
                    .input-group {
                        border-radius: 11px;
                        height: 53px;
                        .input-group-text {
                            background: #f6f6f6;
                            border: none;
                            border-radius: 11px 0px 0px 11px;
                            padding: 15px;
                            font-weight: bold;
                            width: 100%;
                            &:after {
                                display: none;
                            }
                        }

                        .select-icon {
                            font-weight: blod;
                            color: #a4641a;
                            font-size: 30px;
                            text-align: center;
                        }

                        .form-control {
                            background: #f6f6f6;
                            border: none;
                            border-radius: 0px 11px 11px 0px;
                            padding: 15px;
                            font: normal normal 600 20px/22px Open Sans;
                            text-align: right;
                        }
                        .custom-radius {
                            border-radius: 11px;
                        }
                        select {
                            flex: 1;
                            background: #f6f6f6 url('~@/assets/tgd/lightbox/box2-arrow.svg') no-repeat 95%;
                            border: none;
                            border-radius: 11px 0px 0px 11px;
                            padding: 15px;
                            padding-right: 30px;
                            font: normal normal 600 16px/22px Open Sans;
                            appearance: none;
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }

                        .btn {
                            width: 100%;
                            height: 70px;
                            border-radius: 11px;
                            padding: 15px;
                            box-shadow: 0px 3px 6px #00000029;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font: normal normal bold 18px/24px Microsoft JhengHei;
                            letter-spacing: 0px;
                            @include phone {
                                height: 60px;
                            }
                            span {
                                position: relative;
                                top: 2px;
                            }
                            .price {
                                display: flex;
                                align-items: center;
                                font: normal normal 600 16px/22px Open Sans;
                                strong {
                                    font: normal normal bold 29px/39px Open Sans;
                                    letter-spacing: 0px;
                                    padding-left: 20px;
                                }
                            }
                        }
                        .btn-cancel {
                            background: #a2a2a2 0% 0% no-repeat padding-box;
                            justify-content: center;
                        }
                        .btn-checkout {
                            background: #d94343;
                        }
                    }
                }
            }
        }
    }
    .cash-wrap,
    .external-info-wrap {
        width: calc(100% - 70px);
        padding-right: 10px;
        display: inline-block;
        :deep(.form-control.border-radius-all) {
            border-radius: 11px !important;
        }
    }

    .violation {
        :deep(.base-select) {
            .arrow-icon {
                background: transparent linear-gradient(1deg, #cf9064 0%, #9c5828 61%, #81471d 100%) 0 0 no-repeat;
            }
        }
    }
}

.input-wrap {
    position: relative;
    span {
        font-size: 12px;
        color: #f82e2e;
        position: absolute;
        left: 0;
        top: 85%;
    }
}

:deep(.dp__input) {
    padding-left: 14px !important;
    font-family: 'Open Sans', '微軟正黑體', 'Microsoft JhengHei', '黑體', '蘋果儷中黑', serif;
    font-size: 16px !important;
}

:deep(.side-menu-bottom) {
    .side-menu2-item .input-group {
        height: auto !important;
    }
}

:deep(.base-radio) {
    margin-right: 10px !important;
    margin-bottom: 10px !important;
}
</style>
