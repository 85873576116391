<template>
    <div>
        <div
            class="mask"
            v-show="isShow"
            @click="closeStartServiceLightbox(), CloseTab()"
        ></div>
        <transition name="slide" v-if="serviceListData.answer_list">
            <div class="checkout-lightbox" v-show="isShow">
                <div class="d-flex align-items-start flex-column flex-md-row">
                    <div
                        class="nav nav-pills"
                        role="tablist"
                        aria-orientation="vertical"
                    >
                        <button
                            class="nav-link"
                            data-bs-toggle="pill"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            v-for="(tab, index) in serviceListData.answer_list.demand"
                            :key="tab.reserve_details_id"
                            :data-bs-target="`#tab-${index}`"
                            :aria-controls="`tab-${index}`"
                            :class="{ active: index === 0 }"
                        >
                            客戶{{ index + 1 }}
                        </button>
                    </div>
                    <div class="tab-content">
                        <Order
                            v-for="(customer, index) in serviceListData.answer_list.demand"
                            :key="`${customer.reserve_details_id}${index}`"
                            :id="`tab-${index}`"
                            :class="{ active: index === 0 }"
                            :orderData="serviceListData"
                            :idx="index"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import useTabOpen from "@/hooks/common/useTabOpen";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";

import Order from "@/components/lightbox/startService/Order";

export default {
    name: "StartServiceLightbox",
    components: {
        Order,
    },
    props: {
        orderId: {
            type: Number,
            required: true,
        },
        time: {
            type: String,
            default: "00:00"
        }
    },
    setup(props) {
        const { state, dispatch } = useStore();
        const storeId = computed(() => state.auth.store_id);
        const isShow = computed(() => state.global.isStartServiceLightboxShow);
        const { tab1, tab2, OpenTab, CloseTab } = useTabOpen();
        const { closeStartServiceLightbox } = useHandleLightboxOpen();
        const serveId = computed(() => props.orderId);
        const time = computed(() => props.time);
        let serviceListData = computed(() => state.startServiceLightbox.list);

        // 取得本日預約服務清單(打開時都取)
        watch(isShow, (val) => {
            if(!val) return;
            dispatch('startServiceLightbox/getServiceList', {
                store_id: storeId.value,
                reserve_id: serveId.value,
                time: time.value
            });
        });

        return {
            isShow,
            closeStartServiceLightbox,
            tab1,
            tab2,
            OpenTab,
            CloseTab,
            serviceListData,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}

.nav-pills {
    flex-direction: column;
    @include phone{
        flex-direction: row;
        align-items:center;
        height: 80px;
        background: transparent
            linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
            no-repeat padding-box;
        box-shadow: -3px -4px 41px #2e2e2e5e;
        border: 1px solid #ffffff;
        padding: 0 10px;
        width: 100%;
        z-index: 5;
        .nav-item {
            display: flex;
            align-items: flex-end;
            text-align: center;
            margin-bottom: 10px;
            button {
                font: normal normal bold 15px/20px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #000000;
                border-radius: none;
            }
        }
        .active {
            color: #a4641a !important;
            background: unset;
            border-bottom: 2px solid #a4641a;
            border-radius: 0;
        }
    }
    .nav-link {
        background: #e1e1e1 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        width: 100px;
        height: 240px;
        border-radius: 9px 0px 0px 9px;
        font: normal normal bold 16px/21px Microsoft JhengHei;
        letter-spacing: 0px;
        color: #707070;
        position: relative;
        right: -20px;
        @include phone{
            box-shadow:unset !important;
            background: transparent !important;
            height: 35px;
            width: 80px;
            border-radius:0 ;
            font: normal normal bold 15px/20px Microsoft JhengHei;
            right: 0;
        }

    }
    .active {
        background: transparent
            linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
            no-repeat padding-box !important;
        box-shadow: -3px -4px 41px #2e2e2e5e;
        font: normal normal bold 18px/24px Microsoft JhengHei;
        color: #a4641a !important;
        width: 120px;
        right: 0;
        @include phone{
            background: transparent !important;
            font: normal normal bold 15px/20px Microsoft JhengHei;
            width: 80px;
        }
    }
}

.tab-content {
    background: #e1e1e1 0% 0% no-repeat padding-box;
    @include phone{
        background: transparent linear-gradient(180deg, #ECECEC 0%, #F9F9F9 35%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
        box-shadow: -3px -4px 41px #2E2E2E5E;
        border: 1px solid #FFFFFF;
        width: 100%;
        position: relative;
        top: -80px;
    }
}

$side-menu-width: 760px;
.checkout-lightbox {
    position: fixed;
    top: 0;
    right: 0;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;
    @include phone{
        width: 100%;
    }

}


</style>
